<template>
  <div class="project-update-bankers-allocation">
    <div class="card mb-1">
      <h1 class="d-flex justify-content-between align-items-center p-3">
        Allocate Bankers
        <fd-button @click="toProject">
          <i class="fas fa-list-ul"></i>
        </fd-button>
      </h1>
    </div>

    <project-detail-header :project="project"></project-detail-header>

    <fd-form class="card p-1 sm-p-3" @submit="updateBankerAllocation">
      <assign-bankers
        ref="assignBankers"
        isEdit
        v-model="bankers"
        required
        :type="type"
        :projectId="projectId"
        :mapValue="(val) => val.id"
        @update="(val) => (newBankers = val)"
      ></assign-bankers>
      <fd-button
        type="submit"
        class="main medium fixed round"
        :disabled="newBankers.length < 1 || isUpdatingBanker"
        loadingEnabled
        :isLoading="isUpdatingBanker"
        icon="fas fa-edit"
      >
        Update
      </fd-button>
    </fd-form>
  </div>
</template>

<script>
import ProjectAPI from "@/modules/Project/api/project";

export default {
  components: {
    AssignBankers: () =>
      import("@/modules/Project/components/AssignBankers/AssignToProject"),
    ProjectDetailHeader: () =>
      import("@/modules/Project/components/ProjectDetail/Header")
  },
  mixins: [],
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      validator: (val) => ["agency", "developer"].includes(val)
    }
  },
  data: function () {
    return {
      project: {},
      bankers: [],
      newBankers: [],
      projectAPI: new ProjectAPI(this.type),

      isUpdatingBanker: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getProject();
    },
    toProject() {
      this.$router.push({
        name: "ManageProjects"
      });
    },
    async getProject() {
      try {
        let data = await this.projectAPI.getProject(this.projectId);
        this.project = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch project details. Please try again later."
        });
      }
    },
    async updateBankerAllocation() {
      try {
        this.isUpdatingBanker = true;

        await this.projectAPI.addAssignedBankers(this.projectId, {
          bankerIds: this.newBankers
        });

        await this.$refs["assignBankers"].reloadAssignedBankers();

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Banker's allocation has been updated successfully."
        });
        this.isUpdatingBanker = false;
      } catch (error) {
        this.isUpdatingBanker = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update banker's allocation. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
